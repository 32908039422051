import React from 'react'
import styles from './product.module.css'

function Product() {
    return (
        <div className={styles["product-container"]}>
            <div className={styles["product-img-container"]}>
                <img src='/assets/warmhands.png' alt="heat hands" className={styles["product-img"]} />
            </div>
            <div className={styles["product-text-container"]}>
                <h1>Heat Hands <span style={{
                    fontWeight: "normal",
                    fontSize: "18px"
                }}>Your rescue in this cold</span></h1>
                {/* <h3>Join Our Waitlist</h3> */}

                {/* Seprator  */}
                <hr className={styles["sep"]} />
                {/* END Seprator  */}

                <p style={{
                    margin: "10px 0px"
                }}>
                    Heat Hands offers a wide range of high-quality hand warming products designed to keep you warm and cozy in any situation. Whether you're braving the cold outdoors, working in a chilly office, or simply looking for some extra warmth during the winter months, our products are here to provide you with the ultimate comfort.
                </p>

                {/* Seprator  */}
                <hr className={styles["sep"]} />
                {/* END Seprator  */}

                <div style={{
                    margin: "20px 0px"
                }}>
                    <div className={styles["early-bird-badge"]}>Early Bird Offer</div>
                    <div className={
                        styles["price-box"]
                    }>
                        <span style={{
                            color: "#CC0C39",
                            marginRight: "8px",
                            fontWeight: "100"
                        }}>-30%</span>
                        ₹350/-
                    </div>
                    <p>M.R.P ₹500 (for 200ml)</p>
                </div>

                {/* Seprator  */}
                <hr className={styles["sep"]} />
                {/* END Seprator  */}

                <div className="home-btn-group">
                    <button className="buttonFilled">Shop Now</button>
                </div>

            </div>
        </div>
    )
}

export default Product